#domh {
  content: '32768';
  height: 0px;
  width: 100%;
}
@media (max-width: 32768px) {
  #domh {
  	content: '32768';
  }
}
@media (max-width: 16384px) {
  #domh {
  	content: '16384';
  }
}
@media (max-width: 8192px) {
  #domh {
  	content: '8192';
  }
}
@media (max-width: 4096px) {
  #domh {
  	content: '4096';
  }
}
@media (max-width: 3072px) {
  #domh {
  	content: '3072';
  }
}
@media (max-width: 2560px) {
  #domh {
  	content: '2560';
  }
}
@media (max-width: 2048px) {
  #domh {
  	content: '2048';
  }
}
@media (max-width: 1920px) {
  #domh {
  	content: '1920';
  }
}
@media (max-width: 1600px) {
  #domh {
  	content: '1600';
  }
}
@media (max-width: 1550px) {
  #domh {
	content: '1550';
  }
}
@media (max-width: 1500px) {
  #domh {
  	content: '1500';
  }
}
@media (max-width: 1450px) {
  #domh {
	content: '1450';
  }
}
@media (max-width: 1400px) {
  #domh {
  	content: '1400';
  }
}
@media (max-width: 1350px) {
  #domh {
	content: '1350';
  }
}
@media (max-width: 1300px) {
  #domh {
  	content: '1300';
  }
}
@media (max-width: 1250px) {
  #domh {
	content: '1250';
  }
}
@media (max-width: 1200px) {
  #domh {
  	content: '1200';
  }
}
@media (max-width: 1150px) {
  #domh {
	content: '1150';
  }
}
@media (max-width: 1100px) {
  #domh {
	content: '1100';
  }
}
@media (max-width: 1050px) {
  #domh {
	content: '1050';
  }
}
@media (max-width: 1024px) {
  #domh {
  	content: '1024';
  }
}
@media (max-width: 1000px) {
  #domh {
	content: '1000';
  }
}
@media (max-width: 992px) {
  #domh {
	content: '992';
  }
}
@media (max-width: 950px) {
  #domh {
	content: '950';
  }
}
@media (max-width: 900px) {
  #domh {
	content: '900';
  }
}
@media (max-width: 850px) {
  #domh {
	content: '850';
  }
}
@media (max-width: 800px) {
  #domh {
	content: '800';
  }
}
@media (max-width: 768px) {
  #domh {
	content: '768';
  }
}
@media (max-width: 750px) {
  #domh {
	content: '750';
  }
}
@media (max-width: 700px) {
  #domh {
	content: '700';
  }
}
@media (max-width: 650px) {
  #domh {
	content: '650';
  }
}
@media (max-width: 600px) {
  #domh {
	content: '600';
  }
}
@media (max-width: 550px) {
  #domh {
	content: '550';
  }
}
@media (max-width: 500px) {
  #domh {
	content: '500';
  }
}
@media (max-width: 480px) {
  #domh {
	content: '480';
  }
}
@media (max-width: 450px) {
  #domh {
	content: '450';
  }
}
@media (max-width: 400px) {
  #domh {
	content: '400';
  }
}
@media (max-width: 350px) {
  #domh {
	content: '350';
  }
}
@media (max-width: 300px) {
  #domh {
	content: '300';
  }
}
@media (max-width: 250px) {
  #domh {
	content: '250';
  }
}
@media (max-width: 200px) {
  #domh {
	content: '200';
  }
}
@media (max-width: 768px) {
  #domh {
	height: 51px;
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
}
.container:after {
  clear: both;
  display: table;
  content: " ";
}
@media (min-width: 1383px) {
  .container {
    max-width: 1336px;
  }
}
@media only screen and (min-width: 1383px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.lazyload_blog,
img.lazyload,
img.lazyloading {
  visibility: hidden;
  /*opacity: 0!important;*/
  /*transition: opacity 100ms;*/
}
img.lazyloaded {
  visibility: visible;
  /*opacity: 1!important;*/
}
*,
:after,
:before {
  box-sizing: border-box;
}
*:active {
  outline: none;
}
*:focus {
  outline: none;
}
html {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-family: sans-serif;
  font-size: 13px;
  line-height: 13px;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  vertical-align: baseline;
}
body {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  vertical-align: baseline;
  font-family: "Open Sans", Helvetica, sans-serif;
  background-color: #FFFFFF;
  height: 100%;
  font-size: 13px;
  line-height: 18px;
  color: #212121;
}
a {
  font-family: "Open Sans", Helvetica, sans-serif;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  color: #212121;
  text-decoration: none;
  outline-color: rgba(256, 256, 256, 0) !important;
}
a:hover {
  color: #212121;
  text-decoration: none;
}
a:focus {
  color: #212121;
  text-decoration: none;
  outline: #212121 dotted thin;
  outline-offset: -2px;
}
article {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
b {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-weight: 600;
}
div {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
fieldset {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
footer {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
form {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
h1, 
.h1 {
  margin: 5px 0px 5px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  vertical-align: baseline;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 1.1;
}
h2, 
.h2 {
  margin: 5px 0px 5px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  vertical-align: baseline;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-weight: 500;
  font-size: 27px;
  line-height: 1.1;
}
h3, 
.h3 {
  margin: 0px 0px 10px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  vertical-align: baseline;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-weight: 500;
  font-size: 23px;
  line-height: 1.1;
}
h4, 
.h4 {
  margin: 5px 2px 5px 2px;
  padding: 0px 0px 0px 0px;
  border: 0;
  vertical-align: baseline;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.1;
}
h5, 
.h5 {
  margin: 5px 2px 5px 2px;
  padding: 0px 0px 0px 0px;
  border: 0;
  vertical-align: baseline;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.1;
}
h6, 
.h6 {
  margin: 5px 2px 5px 2px;
  padding: 0px 0px 0px 0px;
  border: 0;
  vertical-align: baseline;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.1;
}
i {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
img {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: none;
  font-size: 100%;
  vertical-align: middle;
}
label {
  display: inline-block;
  margin: 0px 0px 5px 0px;
  border: 0;
  padding: 0px 0px 0px 0px;
  font-weight: 600;
  font-size: 100%;
  white-space: normal;
  vertical-align: baseline;
}
label.required:before {
  content: "*";
  color: red;
  font-size: 14px;
  position: relative;
  line-height: 12px;
}
li {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
p {
  margin: 0px 0px 9px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
section {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
span {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
strong {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  font-weight: 600;
  vertical-align: baseline;
}
sup {
  position: relative;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 75%;
  vertical-align: baseline;
  line-height: 0;
  top: -0.5em;
}
ul {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
}
ul ul {
  margin-bottom: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0;
  border-top: 1px solid #eee;
}
button {
  font-family: "Open Sans", Helvetica, sans-serif;
  margin: 0px 0px 0px 0px;
  background-image: none;
  outline-color: rgba(256, 256, 256, 0) !important;
  text-transform: none;
  appearance: button;
  cursor: pointer;
}
button[readonly] {
  cursor: not-allowed !important;
  background-color: #9B9B9B !important;
}
button[disabled] {
  cursor: not-allowed !important;
  background-color: #9B9B9B !important;
}
input {
  font-family: "Open Sans", Helvetica, sans-serif;
  margin: 0px 0px 0px 0px;
  background-image: none;
}
select {
  font-family: "Open Sans", Helvetica, sans-serif;
  margin: 0px 0px 0px 0px;
}
select[multiple] {
  background-image: none;
}
textarea {
  font-family: "Open Sans", Helvetica, sans-serif;
  overflow: auto;
  vertical-align: top;
  resize: none;
  margin: 0px 0px 0px 0px;
  background-image: none;
}
@media (min-width: 992px) {
  .col-md-0 {
    float: left;
	width: 0%;
  }
  .col-md-1 {
    float: left;
	width: 8.33333%;
  }
  .col-md-2 {
    float: left;
	width: 16.66667%;
  }
  .col-md-3 {
    float: left;
	width: 25%;
  }
  .col-md-4 {
    float: left;
	width: 33.33333%;
  }
  .col-md-5 {
    float: left;
	width: 41.66667%;
  }
  .col-md-6 {
    float: left;
	width: 50%;
  }
  .col-md-7 {
    float: left;
	width: 58.33333%;
  }
  .col-md-8 {
    float: left;
	width: 66.66667%;
  }
  .col-md-9 {
    float: left;
	width: 75%;
  }
  .col-md-10 {
    float: left;
	width: 83.33333%;
  }
  .col-md-11 {
    float: left;
	width: 91.66667%;
  }
  .col-md-12 {
    width: 100%;
  }
}
.col-lg-0 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-1 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-2 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-3 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-5 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-6 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-7 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-8 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-9 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-10 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-11 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-0 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-1 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-2 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-3 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-5 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-6 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-7 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-8 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-9 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-10 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-11 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-md-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-0 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-1 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-2 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-3 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-5 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-6 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-7 {
  position: relative;
  min-height: 1px;
  padding-left: 0px;
  padding-right: 0px;
}
.col-sm-8 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-9 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-10 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-11 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-sm-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-xs-0 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 0%;
}
.col-xs-1 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 8.33333%;
}
.col-xs-2 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 16.66667%;
}
.col-xs-3 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 25%;
}
.col-xs-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 31.33333%;
}
.col-xs-5 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 41.66667%;
}
.col-xs-6 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 50%;
}
.col-xs-7 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 58.33333%;
}
.col-xs-8 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 66.66667%;
}
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 75%;
}
.col-xs-10 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 83.33333%;
}
.col-xs-11 {
  position: relative;
  min-height: 1px;
  padding-left: 0px;
  padding-right: 0px;
  float: left;
  width: 91.66667%;
}
.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media (min-width: 768px) {
  .col-sm-0 {
    float: left;
	width: 0%;
  }
  .col-sm-1 {
    float: left;
	width: 8.33333%;
  }
  .col-sm-2 {
    float: left;
	width: 16.66667%;
  }
  .col-sm-3 {
    float: left;
	width: 25%;
  }
  .col-sm-4 {
    float: left;
	width: 33.33333%;
  }
  .col-sm-5 {
    float: left;
	width: 41.66667%;
  }
  .col-sm-6 {
    float: left;
	width: 50%;
  }
  .col-sm-7 {
    float: left;
	width: 58.33333%;
  }
  .col-sm-8 {
    float: left;
	width: 66.66667%;
  }
  .col-sm-9 {
    float: left;
	width: 75%;
  }
  .col-sm-10 {
    float: left;
	width: 83.33333%;
  }
  .col-sm-11 {
    float: left;
	width: 91.66667%;
  }
  .col-sm-12 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .col-md-0 {
    float: left;
	width: 0%;
  }
  .col-md-1 {
    float: left;
	width: 8.33333%;
  }
  .col-md-2 {
    float: left;
	width: 16.66667%;
  }
  .col-md-3 {
    float: left;
	width: 25%;
  }
  .col-md-4 {
    float: left;
	width: 33.333333%;
  }
  .col-md-5 {
    float: left;
	width: 41.66667%;
  }
  .col-md-6 {
    float: left;
	width: 50%;
  }
  .col-md-7 {
    float: left;
	width: 58.33333%;
  }
  .col-md-8 {
    float: left;
	width: 66.66667%;
  }
  .col-md-9 {
    float: left;
	width: 75%;
  }
  .col-md-10 {
    float: left;
	width: 83.33333%;
  }
  .col-md-11 {
    float: left;
	width: 91.66667%;
  }
  .col-md-12 {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-lg-0 {
    float: left;
	width: 0%;
  }
  .col-lg-1 {
    float: left;
	width: 8.33333%;
  }
  .col-lg-2 {
    float: left;
	width: 16.66667%;
  }
  .col-lg-3 {
    float: left;
	width: 25%;
  }
  .col-lg-4 {
    float: left;
	width: 33.33333%;
  }
  .col-lg-5 {
    float: left;
	width: 41.66667%;
  }
  .col-lg-6 {
    float: left;
	width: 50%;
  }
  .col-lg-7 {
    float: left;
	width: 58.33333%;
  }
  .col-lg-8 {
    float: left;
	width: 66.66667%;
  }
  .col-lg-9 {
    float: left;
	width: 75%;
  }
  .col-lg-10 {
    float: left;
	width: 83.33333%;
  }
  .col-lg-11 {
    float: left;
	width: 91.66667%;
  }
  .col-lg-12 {
    width: 100%;
  }
}
/* usuwamy obramowanie po wciśnięciu przycisku */
.pbtn {
  display: table !important;
  outline: none !important;
  border: 1px solid #F4A700 !important;
  border-radius: 0px / 0px !important;
  margin: 10px auto 10px auto;
  padding: 6px 12px 6px 12px;
  text-align: center !important;
  text-decoration: none !important;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-weight: 600 !important;
  line-height: 1.42857 !important;
  color: #FFFFFF !important;
  cursor: pointer !important;
  vertical-align: middle !important;
  box-shadow: none !important;
  user-select: none !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH4wEEDB4rn+FCkAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAMSURBVAjXY/iynBEABC8BnSLN56YAAAAASUVORK5CYII=') !important;
}
.pbtn.bbtn {
  border: 1px solid #212121 !important; 
  background: #212121 !important;
}

.pbtn.gbtn {
  border: 1px solid #B4B3AF !important; 
  background: #B4B3AF !important;
}
.pbtn .caret {
  border-top-color: #212121;
  margin-left: 0;
}
.pbtn span {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  white-space: normal;
}
@media (max-width: 480px) {
  .pbtn span {
    font-size: 15px;
  }
}
.pbtn span i {
  vertical-align: 0;
}
.pbtn span i.left {
  display: inline-block;
  font-size: 10px;
  vertical-align: 0;
}
.pbtn.active,
.pbtn:active {
  outline: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
  color: #FFFFFF !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY3i8SBcABB4Bs1pBa5sAAAAASUVORK5CYII=') !important;
}
.pbtn.bbtn.active,
.pbtn.bbtn:active,
.pbtn.gbtn.active,
.pbtn.gbtn:active {
  border: 1px solid #F4A700 !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY3i8SBcABB4Bs1pBa5sAAAAASUVORK5CYII=') !important;
}
.pbtn:focus, 
.pbtn:hover {
  outline: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
  color: #FFFFFF !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY3i8SBcABB4Bs1pBa5sAAAAASUVORK5CYII=') !important;
}
.pbtn.bbtn:focus,
.pbtn.bbtn:hover,
.pbtn.gbtn:focus,
.pbtn.gbtn:hover {
  border: 1px solid #F4A700 !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY3i8SBcABB4Bs1pBa5sAAAAASUVORK5CYII=') !important;
}
.pbtn.readonly,
.pbtn.disabled,
.pbtn[readonly],
.pbtn[disabled],
fieldset[readonly] .pbtn, 
fieldset[disabled] .pbtn {
  border: solid 1px #918F90 !important;
  cursor: not-allowed !important;
  text-decoration: none !important;
  pointer-events: none !important;
  color: #FFFFFF !important;
  filter: alpha(opacity=65) !important;
  box-shadow: none !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAANSURBVBhXYwgNDvkPAAP5AfzfNGh+AAAAAElFTkSuQmCC')!important;
}
.abtn {
  display: block !important;
  border: none !important;
  margin: 5px 0px 5px 0px;
  padding: 5px 5px 5px 0px;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 100% !important;
  color: #F4A700;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: none;
  cursor: pointer;
  outline: none !important;
  background: none!important;
  vertical-align: middle !important;
  box-shadow: none !important;
  user-select: none !important;
}
.abtn i {
  display: none;
}
.abtn:hover {
  opacity: 0.7;
}
.abtn.readonly,
.abtn.disabled,
.abtn[readonly],
.abtn[disabled],
fieldset[readonly] .abtn, 
fieldset[disabled] .abtn {
  color: #D6D4D4 !important;
  border: none !important;
  cursor: not-allowed !important;
  text-decoration: none !important;
  pointer-events: none !important;
  opacity: 0.65 !important;
  filter: alpha(opacity=65) !important;
  box-shadow: none !important;
  background: none !important;
}
.button-container .ajax_pre_add_to_cart_button:not(.animatedButton), 
.button-container .ajax_add_to_cart_button:not(.animatedButton) {
  display: inline-block !important;
  margin: 0px 0px 0px 0px !important;
  border: none !important;
  padding: 3px 12px 3px 12px !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 18px !important;
  white-space: normal !important;
  color: #F4A700 !important;
  background: none !important;
  text-align: center !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
}
.button-container .ajax_pre_add_to_cart_button:not(.animatedButton) span,
.button-container .ajax_add_to_cart_button:not(.animatedButton) span {
  font-size: 18px !important;
}
@media(max-width:768px) {
  .button-container .ajax_pre_add_to_cart_button:not(.animatedButton) span,
  .button-container .ajax_add_to_cart_button:not(.animatedButton) span {
    font-size: 23px !important;
  }
}
@media(max-width:480px) {
  .button-container .ajax_pre_add_to_cart_button:not(.animatedButton) span,
  .button-container .ajax_add_to_cart_button:not(.animatedButton) span {
    font-size: 23px !important;
  }
}
.button-container .ajax_pre_add_to_cart_button:not(.animatedButton) span, 
.button-container .ajax_add_to_cart_button:not(.animatedButton) span {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
}
.ajax_pre_add_to_cart_button.animatedButton, 
.ajax_add_to_cart_button.animatedButton {
  overflow: hidden;
  position: absolute !important;
  z-index: 1;
  border: 3px solid #F4A700;
  border-radius: 30px;
  padding: 5px 10px 0px 7px;
  width: 40px !important;
  height: 40px !important;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  font-size: 13px;
  font-weight: 200;
  line-height: 30px !important;
  color: #FFFFFF;
  background-color: #212121;
  text-shadow: none;
  text-align: center;
  text-decoration: none! important;
  white-space: normal;
  transition: all .2s linear;
}
@media (max-width: 768px) {
  .ajax_pre_add_to_cart_button.animatedButton,
  .ajax_add_to_cart_button.animatedButton {
    font-size: 23px;
  }
}
.ajax_pre_add_to_cart_button.animatedButton:hover, 
.ajax_add_to_cart_button.animatedButton:hover {
  background-color: #F4A700;
  width: 170px !important;
  height: 40px !important;
  z-index: 1;
}
@media (max-width: 992px) {
  .button-container .ajax_pre_add_to_cart_button, 
  .button-container .ajax_add_to_cart_button {
    font-size: 14px;
  }
}
body.computer .button-container .ajax_pre_add_to_cart_button, 
body.computer .button-container .ajax_add_to_cart_button {
  display: inline-block !important;
}
body.mobile .button-container .ajax_pre_add_to_cart_button, 
body.tablet .button-container .ajax_pre_add_to_cart_button, 
body.mobile .button-container .ajax_add_to_cart_button, 
body.tablet .button-container .ajax_add_to_cart_button {
  display: block !important;
  width: 98%;
}
body.computer .button-container .ajax_pre_add_to_cart_button span, 
body.computer .button-container .ajax_add_to_cart_button span {
  display: block !important;
}
body.mobile .button-container .ajax_pre_add_to_cart_button span, 
body.tablet .button-container .ajax_pre_add_to_cart_button span, 
body.mobile .button-container .ajax_add_to_cart_button span, 
body.tablet .button-container .ajax_add_to_cart_button span {
  display: inline-block !important;
}
.button-container .ajax_add_to_cart_button_wypelnij {
  display: block;
  margin: 0px 0px 0px 0px;
  border: none;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 40px;
}
.button-container .ajax_add_to_cart_button_wypelnij.yes {
  display: none;
}
.vwp_dodaj_do_konfiguracji:not(.animatedButton) {
  display: inline-block !important;
  margin: 0px 0px 0px 0px !important;
  border: none !important;
  padding: 1px 12px 3px 12px !important;
  width: 100% !important;
  height: 39px !important;
  font-size: 20px !important;
  white-space: normal !important;
  color: #F4A700 !important;
  background: none !important;
  text-align: center !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
}
.vwp_dodaj_do_konfiguracji:not(.animatedButton) span {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
  font-size: 20px !important;
}
@media (max-width: 768px) {
  .vwp_dodaj_do_konfiguracji:not(.animatedButton) span {
    font-size: 23px !important;
  }
}
.vwp_dodaj_do_konfiguracji.animatedButton {
  display: none;
  overflow: hidden;
  position: absolute !important;
  border: 3px solid #F4A700;
  border-radius: 30px;
  padding: 5px 10px 0px 7px;
  width: 40px !important;
  height: 40px !important;
  background-color: #212121;
  text-decoration: none! important;
  font-size: 13px;
  font-weight: 200;
  line-height: 30px !important;
  transition: all .2s linear;
  color: #F7F7F7;
}
.vwp_dodaj_do_konfiguracji.animatedButton:hover {
  background-color: #F4A700;
  width: 170px !important;
  height: 40px !important;
  z-index: 1;
}
.vwp_wycena_wycen:not(.animatedButton) {
  display: inline-block !important;
  margin: 0px 0px 0px 0px !important;
  border: none !important;
  padding: 3px 12px 3px 12px !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 20px !important;
  white-space: normal !important;
  color: #F4A700 !important;
  background: none !important;
  text-align: center !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
}
.vwp_wycena_wycen:not(.animatedButton) span {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
}
.vwp_wycena_wycen.animatedButton {
  overflow: hidden;
  position: absolute !important;
  margin: 0px 0px 0px 0px;
  border: 3px solid #F4A700 !important;
  border-radius: 30px;
  padding: 5px 10px 0px 7px;
  width: 40px !important;
  height: 40px !important;
  line-height: 30px !important;
  color: #FFFFFF;
  background-color: #212121;
  font-size: 13px;
  font-weight: 200;
  text-align: center !important;
  text-decoration: none! important;
  transition: all .2s linear;
}
.vwp_wycena_wycen.animatedButton:hover {
  background-color: #F4A700;
  width: 170px !important;
  height: 40px !important;
  z-index: 1;
}
.vwp_wycena_wycen:not(.animatedButton).active, 
.vwp_wycena_wycen:not(.animatedButton):active, 
.vwp_wycena_wycen:not(.animatedButton):focus, 
.vwp_wycena_wycen:not(.animatedButton):hover {
  opacity: 0.7;
  color: #F4A700 !important;
  border-color: #F4A700 !important;
  background: none !important;
}
@media (max-width: 768px) {
  #check .page-product-box .vwp_wycena_wycen {
	background: none !important;
	font-size: 20px;
	text-decoration: none;
	padding: 0px !important;
	margin: 0px 0px 0px 0px;
	height: 33px !important;
	text-align: center !important;
  }
  #check .page-product-box .vwp_wycena_wycen:hover {
	text-decoration: none !important;
  }
  #check .page-product-box .vwp_wycena_wycen span {
	display: block;
	padding: 0px !important;
	margin: -5px 0px 0px 0px;
	color: #F4A700;
	white-space: normal;
	font-weight: bold;
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=') repeat !important;
  }
}
.ajax_pre_add_to_cart_button.animatedButton .animatedButtonText, 
.ajax_add_to_cart_button.animatedButton .animatedButtonText, 
.vwp_dodaj_do_konfiguracji.animatedButton .animatedButtonText, 
.vwp_wycena_wycen.animatedButton .animatedButtonText {
  display: block;
  position: absolute !important;
  top: 2px;
  font-size: 21px;
  text-align: center;
  color: #FFFFFF;
  transition: all .2s linear;
}
.ajax_pre_add_to_cart_button.animatedButton .animatedButtonText:before, 
.ajax_add_to_cart_button.animatedButton .animatedButtonText:before, 
.vwp_dodaj_do_konfiguracji.animatedButton .animatedButtonText:before, 
.vwp_wycena_wycen.animatedButton .animatedButtonText:before {
  content: "\f07a";
  font-family: FontAwesome;
  font-weight: 100;
  display: block;
  vertical-align: 5px;
  color: #FFFFFF;
}
.ajax_pre_add_to_cart_button.animatedButton .animatedButtonExtraText, 
.ajax_add_to_cart_button.animatedButton .animatedButtonExtraText, 
.vwp_dodaj_do_konfiguracji.animatedButton .animatedButtonExtraText, 
.vwp_wycena_wycen.animatedButton .animatedButtonExtraText {
  display: block;
  overflow: hidden;
  position: absolute !important;
  top: 2px;
  margin: 0px 0px 0px 10px;
  padding: 0px 20px;
  width: 160px;
  font-size: 14px !important;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
}
.ajax_pre_add_to_cart_button.screen_scene.animatedButton .animatedButtonExtraText:before, 
.ajax_add_to_cart_button.screen_scene.animatedButton .animatedButtonExtraText:before, 
.vwp_dodaj_do_konfiguracji.animatedButton .animatedButtonExtraText:before, 
.vwp_wycena_wycen.animatedButton .animatedButtonExtraText:before {
  display: none !important;
}
.page-subheading {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #212121;
  padding: 0 0 15px;
  line-height: normal;
  margin-bottom: 12px;
  border-bottom: 0 solid #D6D4D4;
}
p.cheque-indent {
  margin-bottom: 9px;
}
li.product-box.item > h5 {
  text-align: center;
}
.address_desc {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.vexonslider_box .vexonslider_show {
  padding-left: 2px;
  padding-right: 2px;
}
#header_logo {
  float: left;
  position: relative;
  min-height: 1px;
  padding: 5px 15px 5px 15px;
  width: 33.33333%;
}
#header_logo img {
  max-width: 100%;
}
/* Nowa sekcja w nagłówku strony - Rudnicki - Start */
#top-info {
  width: 75%;
  height: 38px;
  float: left;
  margin: 0px 0px 0px 0px !important;
  padding: 5px 0px 5px 0px !important;
}
@media (max-width: 1200px) {
  #top-info {
    width: 75%;
  }
}
@media (max-width: 950px) {
  #top-info {
    width: 72%;
  }
}
@media (max-width: 820px) {
  #top-info {
    width: 75%;
  }
}
@media (max-width: 768px) {
  #top-info {
    display: none;
  }
}
#top-info li {
  display: inline-block;
  display: inline-flex;
  float: left;
  width: 25%;
  color: #212121;
  line-height: 15px;
  font-weight: bold;
}
#top-info li:first-child {
  width: 20%;
}
#top-info li:last-child {
  width: 30%;
}
#top-info li i {
  font-size: 27px;
  float: left;
  display: inline-block;
  margin-top: 1px;
  margin-right: 5px;
}
#top-info li i.icon-money:before {
  content: "\f0d6";
}
#top-info li i.icon-refresh:before {
  content: "\f021";
}
#top-info li i.icon-user:before {
  content: "\f007";
}
#top-info li i.icon-leaf:before {
  content: "\f06c";
}
/* Nowa sekcja w nagłówku strony - Rudnicki - Koniec */
.page-heading {
  overflow: hidden;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 0 0 17px;
  margin-bottom: 30px;
  color: #212121;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.clearfix:after {
  clear: both;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
  background: #FFFFFF;
}
.row:before {
  display: table;
  content: " ";
}
.row:after {
  clear: both;
  display: table;
  content: " ";
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.open > a {
  outline: 0;
}
.label {
  line-height: 1;
  vertical-align: baseline;
  font-weight: 600;
  font-size: 100%;
  color: #FFFFFF;
  text-align: center;
  border-radius: 0;
}
.label[href]:focus,
.label[href]:hover {
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
}
.label:empty {
  display: none;
}
.thumbnail {
  display: block;
  padding: 4px;
  line-height: 1.42857;
  background-color: #FFFFFF;
  border: 0px !important;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
a.thumbnail:focus,
a.thumbnail:hover {
  border-color: #212121;
}
.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}
.close {
  float: right;
  font-size: 19.5px;
  font-weight: 600;
  line-height: 1;
  color: #212121;
  text-shadow: 0 1px 0 #FFFFFF;
  filter: alpha(opacity=20);
  opacity: 1;
}
.close:focus,
.close:hover {
  color: #212121;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=50);
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
#columns {
  position: relative;
  background: #FFFFFF;
  padding-bottom: 0;
  padding-top: 0;
}
header {
  position: relative;
  z-index: 2;
  background: #FFFFFF;
}
header .row {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
header .row > .container {
  position: relative;
}
@media (max-width: 768px) {
  #header_logo {
    display: none;
	padding-top: 15px;
	width: 100%;
  }
  #header_logo img {
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  header .col-sm-4 + .col-sm-4 + .col-sm-4,
  header .row #header_logo + #header_logo + #header_logo,
  header .row #header_logo + #header_logo + .col-sm-4,
  header .row #header_logo + .col-sm-4 + #header_logo,
  header .row #header_logo + .col-sm-4 + .col-sm-4,
  header .row .col-sm-4 + #header_logo + #header_logo,
  header .row .col-sm-4 + #header_logo + .col-sm-4,
  header .row .col-sm-4 + .col-sm-4 + #header_logo {
    float: right;
  }
}
.dark {
  color: #212121;
}
.lnk {
  width: 100%;
  background-color: #f3515c;
  margin: 0 auto;
  text-align: center;
}
.box {
  border: 1px solid #D6D4D4;
  margin: 10px 0px 15px 0px;
  padding: 14px 18px 13px;
  line-height: 23px;
  background: #FFFFFF;
  z-index: 0;
}
.box p {
  margin-bottom: 0;
}
.block {
  margin-bottom: 0;
}
.block h4 {
  background: #212121;
  color: #FFFFFF;
  text-transform: uppercase;
}
#center_column {
  padding-bottom: 15px;
}
#header {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  background: url('/img/bg-theme5.png') #cecece;
}
.columns-container {
  background: url('/img/bg-theme5.png') #cecece;
}
@media (max-width: 768px) {
  .columns-container {
    margin: 20px 0px 0px 0px;
  }
}
.header-container {
  background: url('/img/bg-theme5.png') #cecece;
}
.alert {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 0;
  padding: 15px;
  font-weight: 600;
}
.alert:before {
  cursor: pointer;
}
.alert ol {
  padding-left: 15px;
  margin-left: 27px;
}
.alert ol li {
  list-style-type: decimal;
  font-weight: 400;
}
.alert ul {
  margin-bottom: 0;
  margin-left: 27px;
  padding-left: 15px;
}
.alert ul li {
  list-style-type: decimal;
  font-weight: 400;
}
.alert h4 {
  margin-top: 0;
}
.alert .alert-link {
  font-weight: 600;
}
.alert > p {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-success {
  border-color: #32A332;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  background-color: #32A332;
  color: #FFFFFF;
}
.alert-success:before {
  font-family: FontAwesome;
  content: "\f058";
  font-size: 0px;
  vertical-align: 0px; 
  padding-right: 0px; 
}
.alert-success hr {
  border-top-color: #419f49;
}
.alert-success .alert-link {
  color: #e6e6e6;
}
.alert-warning {
  border-color: #F4A700;
  color: #FFFFFF;
  background-color: #F4A700;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/i0TB0ABE0BwB3Tx3sAAAAASUVORK5CYII=') repeat !important;
}
.alert-warning hr {
  border-top-color: #da681c;
}
.alert-warning .alert-link {
  color: #e6e6e6;
}
.alert-danger {
  border-color: #d4323d;
  background-color: #f3515c;
  color: #FFFFFF;
  text-shadow: 0 0 rgba(0, 0, 0, 0.1);
}
.alert-danger:before {
  font-family: FontAwesome;
  content: "\f057";
  float: left;
  font-size: 0px;
  vertical-align: 0px; 
  padding-right: 0px; 
}
.alert-danger hr {
  border-top-color: #c32933;
}
.alert-danger .alert-link {
  color: #e6e6e6;
}
.clear {
  clear: both;
}
.show {
  display: block !important;
}
.hide {
  display: none !important;
}
[hidden] {
  display: none;
}
.hidden {
  display: none !important;
  visibility: hidden !important;
}
.icon,
[class^="icon-"] {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 14px;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  transform: translate(0, 0);
}
.pull-right {
  float: right !important;
  text-align: left;
  padding: 0px 0px 0px 0px;
}
.pull-left {
  float: left !important;
  text-align: left;
  padding: 0px 0px 0px 0px;
}
.pull-left.icon,
.pull-left[class^="icon-"] {
  margin-right: 0.3em;
}
.pull-right.icon,
.pull-right[class^="icon-"] {
  margin-left: 0.3em;
}
[class*=" icon-"]:before,
[class^="icon-"]:before {
  display: inline-block;
  speak: none;
}
a [class*=" icon-"],
a [class^="icon-"] {
  display: inline;
}
[class*=" icon-"].hide,
[class^="icon-"].hide {
  display: none;
}
[class*=" icon-"].pull-left,
[class^="icon-"].pull-left {
  margin-right: 0.3em;
}
[class*=" icon-"].pull-right,
[class^="icon-"].pull-right {
  margin-left: 0.3em;
}
[class*=" icon-"],
[class^="icon-"] {
  font-family: FontAwesome;
  font-weight: 400;
  font-style: normal;
  display: inline;
  width: auto;
  height: auto;
  line-height: normal;
  vertical-align: baseline;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  margin-top: 0;
}
.unvisible {
  display: none;
}